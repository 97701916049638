import React, { useEffect, useState } from 'react';
import AlphabetNav from './AlphabetNav';
import PeopleList from './PeopleList';
import ApiService from '../services/ApiService';
import PersonDetailsModal from './PersonDetailsModal';
import Search from './Search';
import { Person } from 'common-lib/generated-client';
import { useParams, useNavigate } from 'react-router-dom';
import { DIRECTORY_PATH } from 'components-lib';
import { useRollbar } from '@rollbar/react';

const Directory: React.FC = () => {
  const rollbar = useRollbar();
  const [filteredPeople, setFilteredPeople] = useState<Person[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [activeLetters, setActiveLetters] = useState<string[]>([]);
  const [selectedPerson, setSelectedPerson] = useState<Person | null>(null);
  const { code } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchActiveLetters = async () => {
      try {
        const response = await ApiService.firstLetters();
        setActiveLetters(response.firstLetters ?? []);
      } catch (error) {
        rollbar.error('Error fetching active letters:', error as Error);
      }
    };
    fetchActiveLetters();
  }, []);

  useEffect(() => {
    if (code) {
      const fetchPerson = async () => {
        const person = await ApiService.getPerson(code);
        if (person) {
          setSelectedPerson(person);
        }
      };
      fetchPerson().catch((error) => rollbar.error('Error fetching person details:', error));
    } else {
      setSelectedPerson(null); // Clear selected person if code is removed from URL
    }
  }, [code]);

  const handleLetterClick = (letter: string) => {
    setSearchQuery(letter);
    fetchPeople(letter);
  };

  const handleSearchChange = (query: string) => {
    setSearchQuery(query);
    if (query.length > 0) {
      fetchPeople(query);
    } else {
      setFilteredPeople([]);
    }
  };

  const handleViewDetails = (person: Person) => {
    navigate(`${DIRECTORY_PATH}/${person.authorCode}`);
  };

  const handleCloseModal = () => {
    setSelectedPerson(null);
    navigate(DIRECTORY_PATH); // Navigate back to the directory path
  };

  const fetchPeople = async (query: string) => {
    try {
      const result = await ApiService.search(query);
      setFilteredPeople(result.people || []);
    } catch (error) {
      console.error('Error fetching search results:', error);
    }
  };

  return (
    <>
      {selectedPerson && <PersonDetailsModal person={selectedPerson} onClose={handleCloseModal} />}
      {!selectedPerson && (
        <>
          <Search query={searchQuery} onSearchChange={handleSearchChange} />
          <AlphabetNav activeLetters={activeLetters} onLetterClick={handleLetterClick} />
          <PeopleList people={filteredPeople} onViewDetails={handleViewDetails} />
        </>
      )}
    </>
  );
};

export default Directory;
