/* tslint:disable */
/* eslint-disable */
/**
 * API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ApiYoutubePostRequest
 */
export interface ApiYoutubePostRequest {
    /**
     * The id associated with the YouTube video.
     * @type {string}
     * @memberof ApiYoutubePostRequest
     */
    videoId?: string;
    /**
     * Hash of the media data.
     * @type {string}
     * @memberof ApiYoutubePostRequest
     */
    hash?: string;
    /**
     * URL for a thumbnail image associated with the video.
     * @type {string}
     * @memberof ApiYoutubePostRequest
     */
    thumbnailUrl?: string;
    /**
     * The quality of the video (e.g., "360p").
     * @type {string}
     * @memberof ApiYoutubePostRequest
     */
    quality?: string;
    /**
     * The format of the video (e.g., "mp4", "webm").
     * @type {string}
     * @memberof ApiYoutubePostRequest
     */
    videoFormat?: string;
    /**
     * The size of the asset in bytes.
     * @type {number}
     * @memberof ApiYoutubePostRequest
     */
    contentLength?: number;
}

/**
 * Check if a given object implements the ApiYoutubePostRequest interface.
 */
export function instanceOfApiYoutubePostRequest(value: object): value is ApiYoutubePostRequest {
    return true;
}

export function ApiYoutubePostRequestFromJSON(json: any): ApiYoutubePostRequest {
    return ApiYoutubePostRequestFromJSONTyped(json, false);
}

export function ApiYoutubePostRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiYoutubePostRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'videoId': json['video_id'] == null ? undefined : json['video_id'],
        'hash': json['hash'] == null ? undefined : json['hash'],
        'thumbnailUrl': json['thumbnail_url'] == null ? undefined : json['thumbnail_url'],
        'quality': json['quality'] == null ? undefined : json['quality'],
        'videoFormat': json['video_format'] == null ? undefined : json['video_format'],
        'contentLength': json['content_length'] == null ? undefined : json['content_length'],
    };
}

  export function ApiYoutubePostRequestToJSON(json: any): ApiYoutubePostRequest {
      return ApiYoutubePostRequestToJSONTyped(json, false);
  }

  export function ApiYoutubePostRequestToJSONTyped(value?: ApiYoutubePostRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'video_id': value['videoId'],
        'hash': value['hash'],
        'thumbnail_url': value['thumbnailUrl'],
        'quality': value['quality'],
        'video_format': value['videoFormat'],
        'content_length': value['contentLength'],
    };
}

